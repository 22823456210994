@media (max-width: 1728px) {
	html {
	    font-size: 10px;
	}
	
	body.main-page {
	    height: 100vh;
	}
}

@media (max-width: 1500px) {
	html {
	    font-size: 8px;
	}

    .circular-text {
        display: none;
    }
	
	.phone {
        display: block;
    }
}

@media (min-width: 1000px) {
    .home__wrapper {
	    transform: translateY(20px);
	}
	
	.home__bg-img {
	    height: 70%;
	}
	
	body.main-page {
    	overflow: hidden;
    }
}

@media (max-width: 1200px) {
	html {
	    font-size: 8px;
	}
}

@media (max-width: 1000px) {
	.home__wrapper {
		width: 90.2rem;
	}

	.home__bg-img {
		width: 80%;
	}
}

@media (max-width: 780px) {
	.home__wrapper {
	    -webkit-box-align: start;
	    align-items: flex-start;
	    -webkit-box-orient: vertical;
	    -webkit-box-direction: normal;
	    flex-direction: column;
	    margin: 0;
	    padding: 15rem 3.5rem 0;
	    position: relative;
	}

	body, .home {
	    height: 100%;
		overflow: hidden;
	}

	.contact__description {
		width: 90%;
	}

	.home-opened .header__logo {
		/* display: none; */
	}

	.home__bg-img {
		transform: translateX(50%);
	}

	.home__title-text {
	    font-size: 5.2rem;
		line-height: 1;
	}

	.home__hand {
	    top: 4rem;
	}

	.home__description {
	    line-height: 3.2rem;
	    margin-top: 1.5rem;
	}

	.carousel-item {
		font-size: 3.8rem;
	}

	.home__showreel {
	    bottom: 4.5rem;
	    font-size: 1.8rem;
	    right: 2.5rem;
	    margin-top: 0;
	    position: absolute;
		display: none;
	}

	.home__navigation {
	    margin-top: 4.5rem;
	}

	.home__navigation__arrows {
	    display: none;
	}

	.home__navigation__number {
	    display: none;
	    left: -3rem;
	    top: 1.2rem;
	}

	.home__navigation__item:not(:first-child) {
	    margin-top: 3rem;
	}

	.home__social {
		display: none;
	}

	.custom-cursor, .circle-wrapper {
		opacity: 0;
		display: none;
	}

	.home__navigation__link {
	    font-size: 3.2rem;
	}

	html {
	    font-size: 13px;
	}

	.home__showreel__play {
	    border: 1px solid #8d6f57;
	    border-radius: 50%;
	    height: 5.5rem;
	    padding: 2rem;
	    width: 5.5rem;
	}

	.home {
	    -webkit-box-align: start;
	    align-items: flex-start;
	}

	.header {
	    padding: 0 2.5rem;
	    /* top: 2.5rem; */
	    width: 100%;
		height: 8rem;
	}
}

@media (max-width: 500px) {
	html {
		font-size: 8.7px;
	}
}

@media (min-width: 2000px) {
    /* html {
        font-size: 14px;
    } */

    body {
        height: 100vh;
    }
}

@media (min-width: 2150px) {
    /* html {
        font-size: 15px;
    } */
}

@media (min-width: 2300px) {
    /* html {
        font-size: 16px;
    } */

    .home__wrapper {
        transform: translateY(-20px);
    }
}

@media (max-width: 767px) {
	.about__main {
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		margin: 13rem 0 0;
		padding: 0 3.5rem;
	}

	.about__skills {
		padding: 0 3.5rem;
	}

	.header__logo {
		font-size: 12px;
	}

	.about__skills {
		flex-direction: column;
		padding-bottom: 5rem;
	}

	.about__skills__title {
		font-size: 3.2rem;
		line-height: 1.4;
		width: 100%;
	}

	.about__skills__list {
		height: 24rem;
		margin-top: 3.5rem;
		margin-left: 0px;
		overflow: hidden;
		padding-left: 1.5rem;
		position: relative;
		width: 100%;
	}

	.about__skills__title::before {
		display: none;
	}

	.about__skills__list li {
		font-size: 1.8rem;
	}

	.about__services__skills-row span {
		font-size: 4.9rem;
		margin: 9rem 0;
	}

	.about__more-description {
		font-size: 2.4rem;
		margin: 5rem 0 0;
		width: auto;
	}

	.about__subtitle {
		margin-bottom: 1rem;
	}

	.about__title {
		font-size: 3.2rem;
		margin-bottom: 2rem;
		line-height: 1;
	}

	.contacts {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		padding: 12.5rem 3.5rem 8rem;
		height: 100%;
	}

	.contact__wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
		padding: 4rem 2.5rem;
		width: 100%;
	}

	.contact__column:first-child {
		border-right: none;
		padding-right: 0;
		width: 100%;
	}

	.contact__column:last-child {
		margin-top: 6rem;
		padding-left: 0;
		width: 100%;
	}

	.contact__title {
		font-size: 3.4rem;
	}

	.contact__form {
		height: 20rem;
		margin-top: 3rem;
	}

	.contact__form__field {
		font-size: 1.8rem;
	}

	.contact__information {
		display: flex;
	}

	.contact__information__section {
		width: 50%;
	}

	.contact__information__section:not(:first-child) {
		margin-top: 0px;
	}
}


@media (max-width: 350px) {
	.home__title-text {
		font-size: 4rem;
	}	
}