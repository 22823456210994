@import '../fonts/Axiforma/stylesheet.css';
@import '../fonts/Druk/stylesheet.css';
/* @import "../fonts/Gilroy/stylesheet.css"; */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    cursor: none;
}

*::selection {
    background: none;
}

::-webkit-scrollbar,
::-webkit-scrollbar-track {
    background: 0 0;
}
::-webkit-scrollbar {
    width: 0px;
}
::-webkit-scrollbar-corner {
    background: 0 0;
}

::-webkit-scrollbar-thumb {
    background: rgba(141, 111, 87, 0.1);
    border-radius: 10px;
}

html {
    font-size: 11px;
    /* cursor: none; */
    overflow-x: hidden;
}

body {
    background: #050505;
    color: #fff;
    font-family: 'Axiforma';
    height: 100%;
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}

@keyframes movie {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    10% {
        -webkit-transform: translate(-5%, -5%);
        transform: translate(-5%, -5%);
    }
    20% {
        -webkit-transform: translate(-10%, 5%);
        transform: translate(-10%, 5%);
    }
    30% {
        -webkit-transform: translate(5%, -10%);
        transform: translate(5%, -10%);
    }
    40% {
        -webkit-transform: translate(-5%, 15%);
        transform: translate(-5%, 15%);
    }
    50% {
        -webkit-transform: translate(-10%, 5%);
        transform: translate(-10%, 5%);
    }
    60% {
        -webkit-transform: translate(15%, 0);
        transform: translate(15%, 0);
    }
    70% {
        -webkit-transform: translate(0, 10%);
        transform: translate(0, 10%);
    }
    80% {
        -webkit-transform: translate(-15%, 0);
        transform: translate(-15%, 0);
    }
    90% {
        -webkit-transform: translate(10%, 5%);
        transform: translate(10%, 5%);
    }
    to {
        -webkit-transform: translate(5%, 0);
        transform: translate(5%, 0);
    }
}

.home__title {
    display: inline-block;
    position: relative;
}

.home__title-text {
    font-family: 'Druk Wide';
    font-size: 7.6rem;
    line-height: 0.85;
}

.home__description {
    color: #8d6f57;
    font-size: 1.8rem;
    line-height: 1.8;
    margin-top: 2.5rem;
}

.mobile {
    display: none;
}

.sound-disabled .header__sound__circle {
    transform: translate(calc(-100% + -0.6rem), -50%);
    background: #8d6f57;
}

.sound-disabled .header__sound__bars {
    opacity: 0;
}

.home__hand {
    display: inline-block;
    height: 3.5rem;
    padding-top: 0.8rem;
    position: absolute;
    right: -3.5rem;
    top: 5rem;
    width: 2.5rem;
}

.home__hand-icon {
    background: url('https://i.ibb.co/MV5x46c/emoji-waving-hand.png') 50% /
        contain no-repeat;
    height: 100%;
    width: 100%;
    display: block;
}

.home {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.home__wrapper {
    -webkit-box-align: center;
    align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin: auto;
    width: 124.2rem;
}

.header {
    -webkit-box-align: center;
    align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    left: 0;
    margin: auto;
    /* mix-blend-mode: difference; */
    position: fixed;
    right: 0;
    /* top: 4rem; */
    top: 0;
    width: 85%;
    height: 11rem;
    z-index: 3;
    transition: opacity 0.6s ease;
    transition-delay: 1s;
    opacity: 0;
    /* background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(0, 0, 0, 0) 100%
	); */
}

.loaded .header {
    opacity: 1;
}

.loaded canvas {
    opacity: 0.4;
}

.header__logo {
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: bold;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

a {
    text-decoration: none;
    color: #fff;
}

.grain {
    animation: grain 6s steps(10) infinite;
    background-image: url(../img/grain.png);
    background-repeat: repeat;
    height: 300%;
    left: -100%;
    opacity: 0.06;
    pointer-events: none;
    position: fixed;
    top: -100%;
    width: 300%;
    will-change: transform;
    z-index: 3;
}

.custom-cursor {
    position: fixed;
    pointer-events: none;
    mix-blend-mode: difference;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.7);
    transition: transform 350ms ease;
    transform: translate(-50%, -50%) scale(0.2);
    z-index: 1000;
}

.home__showreel {
    -webkit-box-align: center;
    align-items: center;
    display: -webkit-box;
    display: flex;
    font-size: 2.6rem;
    margin-top: 6.5rem;
    position: relative;
    text-transform: lowercase;
    font-family: 'Druk Wide';
}

.home__showreel__play {
    height: 1.5rem;
    margin-right: 1.5rem;
    width: 1.5rem;
    transform: translateY(-3px);
}

.home__social {
    margin-top: 9rem;
}

.home__social-text {
    letter-spacing: 0.1rem;
    color: #8d6f57;
    font-size: 1.2rem;
    line-height: 1.7;
}

/*ТУТ ЛИШНИЕ КЛАССЫ*/

.footer__contact__social__list,
.contact__social__list,
.home__social__list {
    display: -webkit-box;
    display: flex;
    list-style: none;
    margin-top: 1rem;
}

.home__title-wrapper,
.home__description-wrapper,
.home__showreel-wrapper,
.home__social-wrapper,
.home__hand-wrapper {
    overflow: hidden;
}

.home__hand-wrapper {
    width: 100%;
    height: 100%;
}

.footer__contact__social__item,
.contact__social__item,
.home__social__item {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 700;
    overflow: hidden;
    text-transform: lowercase;
    -webkit-transition: color 0.355s ease-in-out;
    transition: color 0.355s ease-in-out;
}

.footer__contact__social__item:hover,
.contact__social__item:hover {
    color: #8d6f57;
}

.footer__contact__social__item:not(:first-child),
.contact__social__item:not(:first-child),
.home__social__item:not(:first-child) {
    margin-left: 2.4rem;
}

.home__navigation__list {
    list-style: none;
}

.home__navigation__item {
    letter-spacing: 0.1rem;
    position: relative;
}

.home__navigation {
    position: relative;
}

.home__navigation__arrows {
    left: -15rem;
    position: absolute;
    top: 0;
}

.transition-fade,
.about,
.contacts {
    transition: 0.4s;
    opacity: 0;
}

.contacts {
    transition: 2s;
}

.home-opened .transition-fade,
.about-opened .about,
.contacts-opened .contacts {
    opacity: 1;
}

html.is-animating .transition-fade {
    opacity: 0;
}

.home__navigation__arrow:first-child {
    margin-right: -0.6rem;
}
.home__navigation__arrow {
    font-family: 'Druk Wide';
    color: #8d6f57;
    display: inline-block;
    font-size: 8rem;
    line-height: 1;
    opacity: 0;
    -webkit-text-fill-color: tra8sparent;
    -webkit-text-stroke-width: 0.1rem;
}

.home__navigation__link {
    color: hsla(0, 0%, 59.2%, 0.6);
    display: inline-block;
    font-size: 6.2rem;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 0.1rem;
    font-family: 'Druk Wide';
}

.home__navigation__item:not(:first-child) {
    margin-top: 5.5rem;
}

.home__navigation__number {
    color: #8d6f57;
    font-size: 1rem;
    font-weight: 700;
    left: -3.3rem;
    line-height: 1;
    position: absolute;
    top: 1rem;
}

.home__navigation__link:hover {
    color: #fff;
    -webkit-text-fill-color: #fff;
}

.header__sound {
    -webkit-box-align: center;
    align-items: center;
    border: 1px solid #8d6f57;
    border-radius: 8rem;
    display: -webkit-box;
    display: flex;
    font-size: 0;
    height: 3rem;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0 0.9rem;
    position: relative;
    width: 6rem;
    background: transparent;
}

*:focus {
    outline: none;
}

*:hover {
    /* cursor: none; */
}

.header__sound__circle {
    background: #fff;
    border-radius: 50%;
    bottom: 0;
    display: block;
    height: 2rem;
    position: absolute;
    right: 0.6rem;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: background 0.355s ease-in-out,
        -webkit-transform 0.355s ease-in-out;
    transition: background 0.355s ease-in-out,
        -webkit-transform 0.355s ease-in-out;
    transition: background 0.355s ease-in-out, transform 0.355s ease-in-out;
    transition: background 0.355s ease-in-out, transform 0.355s ease-in-out,
        -webkit-transform 0.355s ease-in-out;
    width: 2rem;
    z-index: 3;
}

.header__sound__bars {
    margin: 0.2rem 0 0 0.2rem;
    opacity: 1;
    -webkit-transition: opacity 0.355s ease-in-out;
    transition: opacity 0.355s ease-in-out;
}

.header__sound__bars span {
    animation: soundbars 1s ease-in-out infinite alternate-reverse;
    background: #fff;
    content: '';
    display: inline-block;
    height: 1rem;
    margin: 0 0.12rem;
    -webkit-transform-origin: bottom center;
    transform-origin: bottom center;
    width: 1px;
}

.header__sound__bars span:nth-child(2n) {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
}

.header__sound__off {
    height: 1.7rem;
    margin-top: 0.2rem;
    -webkit-transition: opacity 0.355s ease-in-out;
    transition: opacity 0.355s ease-in-out;
    width: 1.7rem;
}

@-webkit-keyframes soundbars {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
    }
    15% {
        -webkit-transform: scaleY(0.2);
        transform: scaleY(0.2);
    }
    50% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
    }
    75% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
    }
    to {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
}
@keyframes soundbars {
    0% {
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
    }
    15% {
        -webkit-transform: scaleY(0.2);
        transform: scaleY(0.2);
    }
    50% {
        -webkit-transform: scaleY(0.8);
        transform: scaleY(0.8);
    }
    75% {
        -webkit-transform: scaleY(0.4);
        transform: scaleY(0.4);
    }
    to {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }
}

.home__bg-img {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(70%);
    opacity: 0.05;
}

.header__menu__line {
    background: #8d6f57;
    display: block;
    height: 1px;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
    -webkit-transition: margin-bottom 0.2s ease-in-out,
        -webkit-transform 0.355s ease-in-out;
    transition: margin-bottom 0.2s ease-in-out,
        -webkit-transform 0.355s ease-in-out;
    transition: margin-bottom 0.2s ease-in-out, transform 0.355s ease-in-out;
    transition: margin-bottom 0.2s ease-in-out, transform 0.355s ease-in-out,
        -webkit-transform 0.355s ease-in-out;
    width: 100%;
}

.header__menu {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    font-size: 0;
    height: 3.3rem;
    -webkit-box-pack: center;
    justify-content: center;
    pointer-events: none;
    vertical-align: middle;
    width: 5.8rem;
}

.header__menu--active {
    pointer-events: auto;
}

.header__menu--active:hover .header__menu__line:first-child {
    margin-bottom: 1.3rem;
}

.header__menu__line:first-child {
    margin-bottom: 0.9rem;
}

.header__menu__line:last-child {
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

.header__menu--active .header__menu__line {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.about__main,
.portfolio__main {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 20%;
    flex-direction: column;
    margin-top: 22rem;
}

.about__description {
    color: #8d6f57;
    font-size: 1.8rem;
    line-height: 1.8;
}

.el-wrapper {
    overflow: hidden;
}

.about__title,
.portfolio__title {
    margin-bottom: 4.5rem;
    font: 100px/0.9 Druk Wide;
    overflow: hidden;
}

.about__subtitle {
    color: #8d6f57;
    display: block;
    font-size: 1.2rem;
    line-height: 1.7;
    margin-bottom: 1rem;
}

.phone {
    color: rgba(255, 255, 255, 0);
    font-size: 50px;
    font-weight: bolder;
    transition: 0.5s ease-out;
    position: fixed;
    top: 50%;
    display: none;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    font-family: Druk Wide;
    letter-spacing: 5px;
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.6);
}

.about__more-description {
    color: #7f7f7f;
    font-size: 3.4rem;
    margin: 15rem 0 0 0rem;
    line-height: 1.7;
    width: 700px;
}

.about__skills {
    padding: 0px 23%;
    display: flex;
    padding-bottom: 15rem;
    align-items: center;
    justify-content: center;
}

.about__more-description strong {
    color: #fff;
    font-weight: 400;
}

.circular-text {
    position: fixed;
    top: 70%;
    opacity: 0;
    right: 10%;
    transform: translate(-50%, -50%);
    color: rgba(255, 255, 255, 0);
    font-size: 24px;
    font-weight: bolder;
    transition: 0.5s ease-out;
    letter-spacing: 5px;
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.6);
}

.about__services__skills-row {
    display: flex;
}

.about__services__skills-row span {
    color: rgba(141, 111, 87, 0.6);
    display: inline-block;
    font-size: 11rem;
    opacity: 0.6;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 0.1rem;
    white-space: nowrap;
    font-family: 'Druk Wide';
    margin: 18rem 0;
    position: relative;
    will-change: transform;
    /* animation: marquee 4s linear infinite; */
    display: inline-block;
    padding-right: 10px;
}

@keyframes marquee {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.about__skills__title {
    color: #fff;
    font-size: 7rem;
    font-family: Druk Wide;
    line-height: 1.2;
    position: relative;
}

.about__skills__title::before {
    content: '';
    background: url(https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/apple/237/thumbs-up-sign_emoji-modifier-fitzpatrick-type-3_1f44d-1f3fc_1f3fc.png)
        50% / contain no-repeat;
    display: block;
    height: 5.2rem;
    left: -6.6rem;
    position: absolute;
    top: -0.6rem;
    width: 4rem;
}

.about__skills__title strong {
    color: hsla(0, 0%, 59.2%, 0.6);
    display: block;
    font-weight: 500;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 0.1rem;
}

li {
    list-style-type: none;
}

.about__skills__list {
    margin-left: 15rem;
}

.about__skills__list li {
    margin-top: 0.8rem;
    font-size: 2.4rem;
    line-height: 1.7;
}

.about__skills__list li:before {
    color: #8d6f57;
    content: '•';
    font-size: 2.2rem;
    margin-right: 1.2rem;
}

/* body:before {
    background: radial-gradient(circle at center,rgba(0,0,255,.7) 0,#000 115%);
    opacity: .175;
    content: "";
    display: block;
    height: 100vh;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -3;
} */

footer {
    padding: 0px 23%;
    margin-top: 10rem;
    margin-bottom: 16rem;
}

.about__contact__link {
    font-size: 8.4rem;
    color: #fff;
    font-family: Druk Wide;
}

.footer__contact__social__text {
    color: #8d6f57;
    font-size: 1.2rem;
    line-height: 1.7;
    position: relative;
}

.footer__socials {
    margin: 9rem 0 0 4rem;
}

.footer__contact__social__text::before {
    content: '';
    background: url(https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/72/apple/237/smiling-face-with-heart-shaped-eyes_1f60d.png)
        50% / contain no-repeat;
    display: block;
    height: 3rem;
    left: -4rem;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 3rem;
}

.contact__wrapper {
    -webkit-box-align: center;
    align-items: center;
    border: 1px dashed hsla(0, 0%, 84.7%, 0.2);
    display: flex;
    padding: 9rem 10rem;
    position: relative;
    width: 105.4rem;
    height: auto;
}

textarea::placeholder {
    color: #8d6f57;
    letter-spacing: 1.5px;
}

.contacts {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact__column:first-child {
    align-self: flex-start;
    border-right: 1px dashed hsla(0, 0%, 84.7%, 0.2);
    padding-right: 6rem;
    width: 63%;
}

.contact__column:last-child {
    padding-left: 6rem;
    width: 37%;
}

.contact__title {
    overflow: hidden;
    opacity: 1;
    visibility: inherit;
    font: 7rem/1.2 Druk Wide;
    font-size: 8.4rem;
}

.contact__form {
    height: 22rem;
    margin-top: 4rem;
    position: relative;
}

.contact__form__label {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    -webkit-transition: opacity 0.355s ease-in-out,
        visibility 0.355s ease-in-out;
    transition: opacity 0.355s ease-in-out, visibility 0.355s ease-in-out;
    visibility: hidden;
    width: 100%;
}

.contact__form__label.active {
    opacity: 1;
    visibility: visible;
}

.contact__form__footer {
    -webkit-box-align: center;
    align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    left: 0;
    position: absolute;
    -webkit-transition: opacity 0.355s ease-in-out,
        visibility 0.355s ease-in-out;
    transition: opacity 0.355s ease-in-out, visibility 0.355s ease-in-out;
    width: 100%;
}

.contact__description,
.contact__form__error,
.contact__form__field,
.contact__form__label,
.contact__information__text,
.contact__information__title,
.contact__social__text,
.contact__subtitle,
.home__social__text {
    color: #fff;
    font-size: 1.2rem;
    line-height: 1.7;
}

.contact__form__error {
    color: red;
    display: none;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    margin-top: 1rem;
}

.contact__form__message {
    display: none;
}

.contact__subtitle {
    color: #fff;
    font-size: 1.8rem;
}

a,
button {
    color: inherit;
    font-family: 'Axiforma';
    outline: none;
    pointer-events: auto;
}

.contact__form__next,
.contact__form__submit,
.btn {
    border: 0.1rem solid #8d6f57;
    border-radius: 2.7rem;
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 700;
    background: none;
    color: #fff;
    height: 4rem;
    line-height: 4rem;
    padding: 0 2.4rem;
    text-transform: lowercase;
    transition: background 0.355s ease-in-out, border-color 0.355s ease-in-out;
}

.contact__form__next,
.contact__form__submit {
    border-color: hsla(0, 0%, 84.7%, 0.2);
    display: none;
}

.case-information button {
    margin-top: 4.5rem;
}

.contact__form__next.active,
.contact__form__submit.active {
    display: block;
}

.contact__form__steps__step {
    background: #fff;
    border-radius: 0.25rem;
    display: inline-block;
    height: 0.6rem;
    opacity: 0.2;
    -webkit-transition: background 0.355s ease-in-out,
        opacity 0.355s ease-in-out;
    transition: background 0.355s ease-in-out, opacity 0.355s ease-in-out;
    width: 1.3rem;
}

.contact__form__steps__step.active {
    background: #8d6f57;
    opacity: 1;
}

.contact__form__steps__step:not(:first-child) {
    margin-left: 1.1rem;
}

.contact__description {
    color: #7f7f7f;
    margin-top: 1rem;
}

.contact__information {
    margin-top: 4rem;
}

.contact__information__section:not(:first-child) {
    margin-top: 2.5rem;
}

.contact__information__title {
    color: #8d6f57;
}

.contact__information__text {
    color: #7f7f7f;
    margin-top: 0.8rem;
}

.contact__form__field {
    background: transparent;
    border: none;
    color: #7f7f7f;
    font-family: inherit;
    resize: none;
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.7;
}

.contact__form__field--textarea {
    height: 12rem;
}

.contact__form__next:hover,
.contact__form__submit:hover,
.btn:hover {
    border-color: #8d6f57;
    background: #8d6f57;
}

/* .theme-1 .home {
    transform: scale(.8);
    opacity: .3;
} */

/* .slider {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40px, -50%);
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
}

.slider > div {
    width: 80px;
    height: 300px;
    margin-right: 20px;
    filter: grayscale(100%) opacity(50%);
    display: block;
    background: url("https://soniapresne.com/wp-content/uploads/2020/12/templatecover-nesfestive2020.jpg") no-repeat center center;
}

.slider > div:nth-of-type(2n) {
    background: url("https://soniapresne.com/wp-content/uploads/2020/11/templatecover-diplosorti.jpg") no-repeat center center;
} */

.circle-wrapper {
    position: fixed;
    pointer-events: none;
    width: 100vw;
    height: 100vh;
    top: 0;
    transition: opacity 0.3s ease;
    opacity: 0;
    left: 0;
    right: 0;
    display: none;
    overflow: hidden;
}

.circle-bg-orange {
    position: absolute;
    top: 60%;
    left: 55%;
    /* opacity: 0; */
    width: 34vw;
    height: 34vw;
    transition: all 0.1s ease;
    transform: translate(-50%, -50%);
    z-index: -5;
}

.parent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}

.portfolio__title {
    margin: 0 auto;
    font-size: 120px;
}

.projects .item-wrap {
    position: relative;
    margin-bottom: 150px;
}

.projects .item-wrap {
    position: relative;
    margin-bottom: 150px;
}

.projects .image {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
    height: 160px;
    position: relative;
}

.projects {
    width: 100%;
    margin-top: 150px;
}

.projects .image [class*='item-img'] {
    width: 100.5%;
    display: block;
    cursor: pointer;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.projects .image .img-wrap {
    width: 100%;
    height: 160px;
    position: relative;
    overflow: hidden;
    -webkit-transition: 0.4s ease height;
    -o-transition: 0.4s ease height;
    transition: 0.4s ease height;
}

.projects.active .image .img-wrap {
    height: 100%;
    -webkit-transition: 1s ease height;
    -o-transition: 1s ease height;
    transition: 1s ease height;
    -webkit-transition-delay: 0.6s;
    -o-transition-delay: 0.6s;
    transition-delay: 0.6s;
}

.projects .info {
    width: 40%;
    position: absolute;
    top: 0;
    right: 0;
    padding-left: 60px;
}

.projects .item-wrap:nth-child(odd) .info {
    left: 0;
    padding-left: 100px;
    padding-right: 20px;
    right: inherit;
}

.projects .info h3 {
    font-size: 2.1rem;
    color: white;
    line-height: 1.3;
    font-family: 'Druk Wide';
}

.projects .info p {
    max-width: 320px;
    line-height: 1.75;
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.4rem;
    padding-left: 60px;
    padding-top: 25px;
    margin-bottom: 0;
}

.projects .item-wrap:nth-child(odd) .image {
    margin-left: 40%;
}

.home__title-text {
    overflow: hidden;
}

.row-title,
.row-letter {
    font-weight: 500;
    position: relative;
    display: inline-block;
    white-space: nowrap;
}

.row-title {
    overflow: hidden;
}

.soon {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.case-header {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 47rem;
}

.case-title {
    left: 50%;
    position: absolute;
    top: 44rem;
    transform: translate(-50%, -50%);
}

.case-title span {
    color: #8d6f57;
    display: block;
    position: absolute;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 0.1rem;
}

.case-title * {
    font-size: 7.2rem;
    white-space: nowrap;
    font-family: 'Druk Wide';
    transform: translateY(-100%);
}

.case-title span:first-child {
    opacity: 0.2;
    top: -21rem;
}

.case-title span:nth-child(2) {
    opacity: 0.4;
    top: -14rem;
}

.case-title span:nth-child(3) {
    opacity: 0.6;
    top: -7rem;
}

.case-information {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 10rem;
    width: 79rem;
}

.case-information__column h2 {
    color: #8d6f57;
    font-size: 1.2rem;
    line-height: 1.7;
}

.case-information__column span {
    font-size: 1.4rem;
    margin-top: 1.2rem;
    max-width: 270px;
    display: block;
}

.large span {
    font-size: 1.8rem;
    margin-top: 2.2rem;
}

.case-information__section:not(:first-child) {
    margin-top: 3.5rem;
}

.home__social__link,
.contact__information__text span {
    transition: all 0.3s ease;
}

.home__social__link:hover,
.contact__information__text span:hover {
    color: #8d6f57;
}

/* НОВЫЕ */

/* @font-face {
    font-family: 'Gilroy';
    src: url('../fonts/Gilroy/Gilroy-Bold.woff2');
    font-style: normal;
}

body {
	background: #fff;
    font-family: 'Gilroy';
    color: #161f38;
}

* {
    font-family: 'Gilroy';
}

.row-title {
    font-family: 'Gilroy Bold';
} */

.slides {
    align-self: flex-start;
    flex: 1;
    width: 100%;
    overflow: hidden;
}

.carousel-inner {
    position: relative;
    height: 100vh;
    padding-left: 34rem;
}

@media (max-width: 700px) {
    .carousel-inner {
        padding-left: 20px;
    }

    .carousel-item {
        transition: none !important;
    }
}

.shades::before,
.shades::after {
    content: '';
    z-index: 2;
    position: fixed;
    width: 100%;
    height: 25%;
    pointer-events: none;
}

.shades::before {
    top: 0;
    background: linear-gradient(180deg, rgba(5, 5, 5, 1), transparent);
}

.shades::after {
    bottom: 0;
    background: linear-gradient(0deg, rgba(5, 5, 5, 1), transparent);
}

.carousel-item {
    font-family: 'Druk Wide';
    position: absolute;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    opacity: 0;
    top: calc(50% - 44px);
    transition: transform 0.6s ease, opacity 0.4s ease;
    color: transparent;
    font-size: 8rem;
}

.carousel-item::after,
.carousel-item::before {
    content: attr(data-text);
    left: 0;
    position: absolute;
    top: 0;
}

.carousel-item::after {
    color: hsla(0, 0%, 59.2%, 0.6);
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 0.1rem;
    transition: color 0.4s ease;
}

.carousel-item.visible {
    opacity: 1;
}

.carousel-item::before {
    transition: opacity 0.4s ease;
    opacity: 0;
}

.carousel-item.active::before {
    opacity: 1;
    color: #fff;
}

@media (max-width: 800px) {
    .case-title * {
        font-size: 4rem;
    }

    .case-title {
        top: 30em;
    }

    .case-header {
        margin-top: 35em;
    }

    .case {
        padding: 0 3.5rem;
    }

    .case-information {
        flex-direction: column;
        gap: 3.5rem;
        margin-bottom: 5em;
    }

    .case-title span:first-child {
        top: -12rem;
    }

    .case-title span:nth-child(2) {
        top: -8rem;
    }

    .case-title span:nth-child(3) {
        top: -4rem;
    }

    .case-information__column span {
        font-size: 1.8rem;
    }

    .case-information {
        max-width: 100%;
    }

    .case-information__column span {
        max-width: 90%;
    }
}

@media (min-width: 1350px) {
    .home__hand {
        display: inline-block;
        height: 5.5rem;
        padding-top: 0.8rem;
        position: absolute;
        right: -7.5rem;
        top: 2rem;
        width: 5.5rem;
    }
}
