@keyframes noise {
    0% {
        -webkit-transform: translate3d(1%, -1%, 0);
        transform: translate3d(1%, -1%, 0)
    }

    10% {
        -webkit-transform: translate3d(-5%, -2%, 0);
        transform: translate3d(-5%, -2%, 0)
    }

    20% {
        -webkit-transform: translate3d(10%, 5%, 0);
        transform: translate3d(10%, 5%, 0)
    }

    30% {
        -webkit-transform: translate3d(5%, -11%, 0);
        transform: translate3d(5%, -11%, 0)
    }

    40% {
        -webkit-transform: translate3d(-12%, -5%, 0);
        transform: translate3d(-12%, -5%, 0)
    }

    50% {
        -webkit-transform: translate3d(10%, 9%, 0);
        transform: translate3d(10%, 9%, 0)
    }

    60% {
        -webkit-transform: translate3d(15%, 0, 0);
        transform: translate3d(15%, 0, 0)
    }

    70% {
        -webkit-transform: translate3d(-10%, 8%, 0);
        transform: translate3d(-10%, 8%, 0)
    }

    80% {
        -webkit-transform: translate3d(10%, 2%, 0);
        transform: translate3d(10%, 2%, 0)
    }

    90% {
        -webkit-transform: translate3d(1%, 5%, 0);
        transform: translate3d(1%, 5%, 0)
    }

    to {
        -webkit-transform: translate3d(0, 8%, 0);
        transform: translate3d(0, 8%, 0)
    }
}

@keyframes grain {
    0% {
        -webkit-transform: translate(20%, -15%);
        transform: translate(20%, -15%)
    }

    10% {
        -webkit-transform: translate(-20%, -15%);
        transform: translate(-20%, -15%)
    }

    20% {
        -webkit-transform: translate(20%, -5%);
        transform: translate(20%, -5%)
    }

    30% {
        -webkit-transform: translate(-20%, -5%);
        transform: translate(-20%, -5%)
    }

    40% {
        -webkit-transform: translate(20%, 5%);
        transform: translate(20%, 5%)
    }

    50% {
        -webkit-transform: translate(-20%, 5%);
        transform: translate(-20%, 5%)
    }

    60% {
        -webkit-transform: translate(20%, 15%);
        transform: translate(20%, 15%)
    }

    70% {
        -webkit-transform: translate(-20%, 15%);
        transform: translate(-20%, 15%)
    }

    80% {
        -webkit-transform: translate(20%, 5%);
        transform: translate(20%, 5%)
    }

    90% {
        -webkit-transform: translate(-20%, 5%);
        transform: translate(-20%, 5%)
    }

    to {
        -webkit-transform: translate(20%, -5%);
        transform: translate(20%, -5%)
    }
}